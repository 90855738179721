// src/components/Home.js
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';
import ResultsTable from './ResultsTable';
import '../css/PerformancePage.css';
import I18n from '../i18n';
import { LanguageContext } from './LanguageContext';
import { useNavigate } from 'react-router-dom';

const PerformancePage = ({userInfo}) => {

  const { language } = useContext(LanguageContext); // Get the language from context
  const navigate = useNavigate();

  const [loadingTxt, setLoadingTxt] = useState(I18n.t('basic.loading'));
  const [title, setTitle] = useState(I18n.t('performance.title'));

  const [results, setResults] = useState('');
  const [loading, setLoading] = useState(true);
  const apiUrl = config.API_URL;

  const handlePageEntry = () => {
    if (!userInfo) return; // Ensure userInfo is available

    axios.get(`${apiUrl}/results/get_results/${userInfo.uuid}`)
      .then(response => {
        console.log('Test results:', response.data);
        console.log('Test results_per_function:', response.data.results_per_function);
        setResults(response.data.results_per_function);
        setLoading(false); // Set loading to false after fetching
      })
      .catch(error => {
        console.error('Error testing code:', error);
        setLoading(false); // Set loading to false even if there is an error
      });
  };

  useEffect(() => {
    setLoadingTxt(I18n.t('basic.loading'));
    setTitle(I18n.t('performance.title'));
  }, [language]); // Rerun whenever the language changes


  // useEffect to watch for changes in userInfo and call handlePageEntry when userInfo is available
  useEffect(() => {
    if (userInfo) {
      setLoading(true); // Set loading to true when fetching starts
      handlePageEntry();
    }
  }, [userInfo]);
  
  useEffect(() => {
    if (userInfo !== null) {
        const isPayingUser = userInfo && userInfo.expiration_date ? new Date(userInfo.expiration_date) > new Date() : false;
        if (!isPayingUser) {
          alert(I18n.t('account.notPayingUser'));
            navigate('/');
        }

    }
}, [userInfo, navigate]);

if (userInfo === null) {
    return <div>{loading}</div>;
}



  const renderResultsTable = () => {
    console.log('results: ', results);
    return (
        <ResultsTable results={results} />
    )
};


return (
  <div className="performance-page">
    <h2> {title}</h2>
    {loading ? (
      <p>{loadingTxt}</p>
    ) : renderResultsTable()}
  </div>
);
};


export default PerformancePage;
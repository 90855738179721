import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import I18n from '../i18n';
import '../css/AdminPage.css';
import { LanguageContext } from './LanguageContext';
import DisplayUserTable from './tables/DisplayUserTable';
import StatusMessage from './common/StatusMessage';
import LoadingSpinner from './common/LoadingSpinner';

const AdminUsersPage = ({ userInfo }) => {
  const navigate = useNavigate();
  const apiUrl = config.API_URL;
  const { language } = useContext(LanguageContext);

  const [users, setUsers] = useState([]);
  const [editingUserId, setEditingUserId] = useState(null);
  const [editingData, setEditingData] = useState(null);
  const [editingDataMap, setEditingDataMap] = useState({});
  const [newUser, setNewUser] = useState(null);
  const newRowRef = useRef(null);

  const [title, setTitle] = useState(I18n.t('admin.users'));
  const [loading, setLoading] = useState(I18n.t('basic.loading'));

  const [statusMessage, setStatusMessage] = useState(null);
  const [statusType, setStatusType] = useState('success');

  // Add loading states
  const [isLoading, setIsLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState('');

  // Add new state for search
  const [searchTerm, setSearchTerm] = useState('');

  // Add new state for progress
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setTitle(I18n.t('admin.users'));
    setLoading(I18n.t('basic.loading'));
  }, [language]);

  useEffect(() => {
    if (userInfo !== null) {
      const isAdmin = userInfo && userInfo.type === 'admin';
      if (!isAdmin) {
        navigate('/');
      }
    }
  }, [userInfo, navigate]);

  // Fetch users on component mount
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    setLoadingAction('fetching');
    try {
      const response = await axios.get(`${apiUrl}/users/get_all_users`);
      setUsers(response.data.users.filter(user => user.type === 'student'));
    } catch (error) {
      console.error('Error fetching users:', error);
      showStatus(I18n.t('admin.messages.fetchError'), 'error');
    } finally {
      setIsLoading(false);
      setLoadingAction('');
    }
  };

  const handleUserEdit = (user) => {
    setEditingUserId(user.id);
    setEditingData({ ...user });
  };

  const handleUserDelete = async (userId) => {
    if (window.confirm(I18n.t('admin.messages.deleteConfirm'))) {
      setIsLoading(true);
      setLoadingAction('deleting');
      try {
        await axios.delete(`${apiUrl}/users/delete/${userId}`);
        setUsers(users.filter(user => user.id !== userId));
        showStatus(I18n.t('admin.messages.deleteSuccess'));
      } catch (error) {
        console.error('Error deleting user:', error);
        showStatus(I18n.t('admin.messages.deleteError'), 'error');
      } finally {
        setIsLoading(false);
        setLoadingAction('');
      }
    }
  };

  const handleUserSave = async (userData) => {
    setIsLoading(true);
    setLoadingAction('saving');
    try {
      if (!userData.name || !userData.name.trim()) {
        throw new Error(I18n.t('admin.messages.nameRequired'));
      }

      if (!userData.email || !userData.email.trim()) {
        throw new Error(I18n.t('admin.messages.emailRequired'));
      }
      console.log('userInfo:', userInfo);
      console.log('userData:', userData);



      const response = await axios.put(
        `${apiUrl}/users/update/${userData.uuid}`,
        userData,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );



      if (!response?.data) {
        throw new Error('No data returned from server');
      }

      // Clear edit states
      setEditingUserId(null);
      setEditingData(null);
      setNewUser(null);
      setEditingDataMap(prev => {
        const newMap = { ...prev };
        delete newMap[userData.id];
        return newMap;
      });

      // Update users list
      await fetchUsers();

      showStatus(I18n.t('admin.messages.saveSuccess'));
    } catch (error) {
      console.error('Error saving user:', error);
      showStatus(error.message || I18n.t('admin.messages.saveError'), 'error');
    } finally {
      setIsLoading(false);
      setLoadingAction('');
    }
  };

  const handleUserCancel = () => {
    setEditingUserId(null);
    setEditingData(null);
    if (newUser) {
      setNewUser(null);
    }
  };

  const showStatus = (message, type = 'success') => {
    setStatusMessage(message);
    setStatusType(type);
    setTimeout(() => setStatusMessage(null), 3000);
  };

  // Add filter function
  const filteredUsers = users.filter(user => {
    const searchLower = searchTerm.toLowerCase();
    return (
      user.name?.toLowerCase().includes(searchLower) ||
      user.email?.toLowerCase().includes(searchLower) ||
      user.institute?.toLowerCase().includes(searchLower) ||
      user.department?.toLowerCase().includes(searchLower)
    );
  });

  // Add search input handler
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Modify handleCsvUpload
  const handleCsvUpload = async (event) => {
    const file = event.target.files[0];
    console.log('Selected file:', file);
    if (!file) return;
    
    if (!file.name.endsWith('.csv')) {
      showStatus(I18n.t('admin.messages.csvRequired'), 'error');
      return;
    }

    setIsLoading(true);
    setLoadingAction('uploading');
    setIsUploading(true);
    setUploadProgress(0);

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        console.log('CSV content:', text);
        const rows = text.split('\n').map(row => row.trim()).filter(row => row);
        console.log('Parsed rows:', rows);
        const dataRows = rows.slice(1);
        const totalRows = dataRows.length;
        let processedRows = 0;

        for (const row of dataRows) {
          const [email, days, confirmed] = row.split(',').map(field => field.trim());
          console.log('Processing row:', { email, days, confirmed });
          
          if (confirmed !== '1') {
            console.log('Skipping unconfirmed user:', email);
            processedRows++;
            setUploadProgress(Math.round((processedRows / totalRows) * 100));
            continue;
          }

          try {
            console.log('Getting user info for:', email);
            const userResponse = await axios.get(`${apiUrl}/users/get_by_email/${email}`);
            console.log('User response:', userResponse.data);
            const userUuid = userResponse.data.user_info.uuid;

            if (!userUuid) {
              console.warn(`No user found with email: ${email}`);
              processedRows++;
              setUploadProgress(Math.round((processedRows / totalRows) * 100));
              continue;
            }

            const futureDateObj = new Date();
            futureDateObj.setDate(futureDateObj.getDate() + parseInt(days));

            // Format date as YYYY-MM-DD
            const futureDate = futureDateObj.toISOString().split('T')[0];

            console.log('Updating user:', userUuid, 'with date:', futureDate);

            const updateResponse = await axios.put(`${apiUrl}/users/update_user/${userUuid}`, null, {
              params: {
                field: 'expiration_date',
                value: futureDate  // Will be like "2024-03-21"
              }
            });
            console.log('Update response:', updateResponse.data);

            processedRows++;
            setUploadProgress(Math.round((processedRows / totalRows) * 100));
          } catch (error) {
            console.error(`Error updating user ${email}:`, error.response?.data || error.message);
            processedRows++;
            setUploadProgress(Math.round((processedRows / totalRows) * 100));
          }
        }

        await fetchUsers();
        showStatus(I18n.t('admin.messages.usersUpdated'));
      };

      reader.readAsText(file);
    } catch (error) {
      console.error('Error processing CSV:', error);
      showStatus(I18n.t('admin.messages.csvError'), 'error');
    } finally {
      setIsLoading(false);
      setLoadingAction('');
      setIsUploading(false);
      setUploadProgress(0);
      event.target.value = '';
    }
  };

  if (userInfo === null) {
    return <div>{loading}</div>;
  }

  return (
    <div className="admin-page">
      <h2>{title}</h2>
      {isLoading && <LoadingSpinner overlay />}
      <StatusMessage
        message={statusMessage}
        type={statusType}
        onClose={() => setStatusMessage(null)}
      />
      <section className="users-section">
        <div className="search-container">
          <input
            type="text"
            placeholder={I18n.t('admin.searchUsers')}
            value={searchTerm}
            onChange={handleSearch}
            className="search-input"
          />
          <input
            type="file"
            accept=".csv"
            style={{ display: 'none' }}
            id="csvUpload"
            onChange={handleCsvUpload}
          />
          <button
            className="upload-button"
            onClick={() => document.getElementById('csvUpload').click()}
            disabled={isUploading}
          >
            {I18n.t('admin.buttons.uploadConfirmedUsers')}
          </button>
        </div>
        {isUploading && (
          <div className="progress-container">
            <div className="progress-bar">
              <div 
                className="progress-fill"
                style={{ width: `${uploadProgress}%` }}
              />
            </div>
            <div className="progress-text">
              {uploadProgress}%
            </div>
          </div>
        )}
        <DisplayUserTable
          users={filteredUsers}
          editingUserId={editingUserId}
          editingData={editingData}
          editingDataMap={editingDataMap}
          setEditingData={setEditingData}
          newUser={newUser}
          newRowRef={newRowRef}
          handleUserEdit={handleUserEdit}
          handleUserDelete={handleUserDelete}
          handleUserSave={handleUserSave}
          handleUserCancel={handleUserCancel}
          isLoading={isLoading}
          loadingAction={loadingAction}
        />
      </section>
    </div>
  );
};

export default AdminUsersPage;

import React from 'react';
import BaseTable from './BaseTable';
import UserRow from './UserRow';
import { USER_COLUMNS } from './config/tableConfigs';
import I18n from '../../i18n';

const DisplayUserTable = ({
  users,
  editingUserId,
  editingData,
  editingDataMap,
  setEditingData,
  newUser,
  newRowRef,
  handleUserEdit,
  handleUserDelete,
  handleUserSave,
  handleUserCancel,
  isLoading,
  loadingAction
}) => {
  const headers = USER_COLUMNS.map(col => ({
    title: I18n.t(col.label),
    tooltip: I18n.t(col.tooltip),
    className: col.className
  }));

  const renderRow = (user, isEditing, actions) => (
    <UserRow
      user={user}
      isEditing={isEditing}
      editingData={isEditing ? editingDataMap[user.id] || editingData : null}
      setEditingData={setEditingData}
      actions={{
        ...actions,
        onEdit: () => handleUserEdit(user),
        onDelete: () => handleUserDelete(user.id),
        onSave: () => handleUserSave(editingDataMap[user.id] || editingData),
        onCancel: () => handleUserCancel(user.id)
      }}
      isLoading={isLoading}
      loadingAction={loadingAction}
    />
  );

  return (
    <BaseTable
      items={users}
      headers={headers}
      editingId={editingUserId}
      renderRow={renderRow}
      newItem={newUser}
      newRowRef={newRowRef}
      isLoading={isLoading}
      loadingAction={loadingAction}
    />
  );
};

export default DisplayUserTable; 
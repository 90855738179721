import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import config from './config';
import Navbar from './components/Navbar';
import Home from './components/Home';
import PerformancePage from './components/PerformancePage';
import AccountPage from './components/AccountPage';
import AdminPage from './components/AdminPage';
import AdminUsersPage from './components/AdminUsersPage';
import AdminQuestionsPage from './components/AdminQuestionsPage';
import CompilerPage from './components/CompilerPage';
import AboutUsPage from './components/AboutUsPage';
import ContactPage from './components/ContactPage';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import UserPool from './cognitoConfig';

import { LanguageProvider } from './components/LanguageContext';

function App() {
  const [user, setUser] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [name, setName] = useState(null);
  const [uuid, setUuid] = useState(null);
  const apiUrl = config.API_URL;


  const getSession = async () => {
    const currentUser = UserPool.getCurrentUser();
    console.log('Current user:', currentUser);
    if (!currentUser) {
      setUser(null);
      setUserInfo(null);
      return;
    }

    try {
      const session = await new Promise((resolve, reject) => {
        currentUser.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        });
      });

      const attributes = await new Promise((resolve, reject) => {
        currentUser.getUserAttributes((err, attributes) => {
          if (err) reject(err);
          else resolve(attributes);
        });
      });

      const nameAttr = attributes.find(attr => attr.Name === 'name');
      const uuidAttr = attributes.find(attr => attr.Name === 'sub');

      setName(nameAttr ? nameAttr.Value : null);
      setUuid(uuidAttr ? uuidAttr.Value : null);
      setUser(currentUser);
    } catch (error) {
      console.error('Error getting session or attributes:', error);
      setUser(null);
      setUserInfo(null);
    }
  };

  const handleSignInSuccess = (user, name) => {
    setUser(user);
    setName(name);
    localStorage.setItem('name', name);
  };

  const handleSignOut = () => {
    const currentUser = UserPool.getCurrentUser();
    if (currentUser) {
      currentUser.signOut();
      setUser(null);
      setUserInfo(null);
      setName(null);
      setUuid(null);
      localStorage.removeItem('name');
    }
  };


  const fetchUserInfo = async () => {
    if (uuid) {
      try {
        const response = await axios.get(`${apiUrl}/users/get_user/${uuid}`);
        console.log('Fetched user info:', response.data);
        setUserInfo(response.data.user_info);
      } catch (error) {
        console.error('Error getting user info:', error);
        setUserInfo(null);
      }
    }
  };

  useEffect(() => {
    console.log('UUID changed:', uuid);
    getSession();
  }, []);

  useEffect(() => {
    fetchUserInfo();
  }, [uuid]);


  return (
    <Router>
      <LanguageProvider>
        <div className="App">
          <Navbar user={user} name={name} userInfo={userInfo} handleSignOut={handleSignOut} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/tests" element={<CompilerPage userInfo={userInfo} />} />
            <Route path="/performance" element={<PerformancePage userInfo={userInfo} />} />
            <Route path="/about-us" element={<AboutUsPage uuid={uuid} />} />
            <Route path="/contact" element={<ContactPage uuid={uuid} />} />
            <Route path="/account" element={<AccountPage userInfo={userInfo} name={name} />} />
            <Route path="/signin" element={<SignIn onSignIn={handleSignInSuccess} />} />
            <Route path="/signup" element={<SignUp onSignIn={handleSignInSuccess} />} />
            <Route path="/admin" element={<AdminPage userInfo={userInfo}/>} />
            <Route path="/admin/users" element={<AdminUsersPage userInfo={userInfo} />} />
            <Route path="/admin/questions" element={<AdminQuestionsPage userInfo={userInfo}/>} />
          </Routes>
        </div>
      </LanguageProvider>

    </Router>
  );
}

export default App;
export default {
  basic: {
    loading: 'טוען...',
    selectLanguage: 'בחר שפה',
  },
  navbar: {
    home: 'דף הבית',
    members: 'אזור אישי',
    account: 'חשבון',
    practice: 'מערכת תרגול',
    performance: 'ביצועים',
    signin: 'התחבר',
    signup: 'הרשם',
    signout: 'התנתק',
    about: 'אודות',
    contact: 'צור קשר',
    admin: 'ניהול',
  },
  home: {
    introTitle: 'שלום וברוכים הבאים לtestapp!',
    introSubTitle: 'מערכת תרגול ייחודית המאפשרת לך להתכונן למבחן עם',
    introSubTitle2: 'הצלחה מובטחת!',
    intro1: 'שלום',
    intro2: 'שמי אליאור שקד',
    intro3: 'אני מזמין אתכם לתרגל את כישורי התכנות שלכם בצורה',
    intro4: 'יצירתית ועצמאית',
    intro5: 'המערכת שלנו מספקת משוב על מבחנים שונים לשאלות נפוצות במבחן, ומאפשרת לך להעריך את איכות הקוד שלך תוך עמידה בדרישות השאלה (רקורסיה, זמן ריצה וכו׳). הדרך שלנו ארוכה, אבל נעשה אותה יחד עם מטרה ברורה - לעזור לך לכתוב קוד אופטימלי',
    intro6: 'ולהצליח במבחן',
    videoError: 'שגיאה בטעינת הוידאו',
  },
  account: {
    title: 'דף החשבון',
    name: 'שם:',
    email: 'דוא"ל:',
    notPayingUser: 'אינך משתמש בתשלום. אנא צור קשר עם המנהל לשדרוג החשבון שלך.',
  },
  contact: {
    questions: 'לשאלות:',
    email: 'דוא"ל:',
  },
  about: {
    founder1: 'אליאור: ילד טוב',
    founder2: 'עמית: ילד רע',
  },
  admin: {
    title: 'דף ניהול - דף סודי!',
    upload: 'העלה קובץ',
    default: 'לא נבחר קובץ',
    verify: 'אמת והעלה CSV',
    alertNoFile: 'לא נבחר קובץ',
    alertSuccess: 'הקובץ עובד בהצלחה',
    alertPleaseUpload: 'אנא העלה קובץ עם סיומת .csv',
    questions: "שאלות",
    tests: "בדיקות",
    addQuestion: "הוסף שאלה חדשה",
    addTest: "הוסף בדיקה חדשה",
    testables: 'רכיבים לבדיקה',
    addTestable: 'הוסף רכיב לבדיקה',
    users: 'משתמשים',
    searchUsers: 'חפש משתמשים לפי שם, דוא"ל, מוסד או מחלקה...',

    buttons: {
      edit: "ערוך",
      delete: "מחק",
      save: "שמור",
      cancel: "בטל",
      duplicate: "שכפל",
      addQuestion: 'הוסף שאלה',
      addTestable: 'הוסף רכיב לבדיקה',
      addTest: 'הוסף בדיקה',
      saving: 'שומר...',
      deleting: 'מוחק...',
      loading: 'טוען...',
      duplicating: 'משכפל...',
      uploadConfirmedUsers: 'העלאת משתמשים מאושרים',
    },

    messages: {
      saveSuccess: "השינויים נשמרו בהצלחה",
      saveError: "שגיאה בשמירת השינויים",
      deleteSuccess: "נמחק בהצלחה",
      deleteError: "שגיאה במחיקת הפריט",
      invalidPythonDict: "הקלט חייב להיות בפורמט מילון פייתון תקין (לדוגמה: {'key': 'value'} או {})",
      duplicateQuestionVersion: "קיימת כבר שאלה עם שם וגרסה זהים",
      duplicateTestName: "קיימת כבר בדיקה בשם זה עבור שאלה זו",
      invalidVersion: "הגרסה חייבת להיות מספר, אותיות, או מספר ואחריו אותיות",
      invalidExpectedOutput: "הפלט הצפוי חייב להיות מספר, True, False, None, אוסף, או מחרוזת בגרשיים",
      deleteConfirm: 'האם אתה בטוח שברצונך למחוק פריט זה?',
      duplicateTestables: 'האם ברצונך לשכפל גם את הרכיבים והבדיקות המשויכים?',
      duplicateSuccess: 'הפריט שוכפל בהצלחה',
      duplicateError: 'שגיאה בשכפול הפריט',
      fetchError: 'שגיאה בטעינת הנתונים',
      nameRequired: 'נדרש שם',
      duplicateQuestionName: 'קיימת כבר שאלה בשם זה',
      duplicateTestableName: 'קיים כבר רכיב בשם זה עבור שאלה זו',
      duplicateTestName: 'קיימת כבר בדיקה בשם זה עבור רכיב זה',
      nameTooLong: 'השם חייב להיות קצר מ-100 תווים',
      nameInvalidChars: 'השם יכול להכיל רק אותיות, מספרים, רווחים וקווים תחתונים',
      duplicateNameSuffix: 'כבר קיים עותק של פריט זה עם שם זה. נסה סיומת אחרת.',
      confirmDeleteTestable: 'האם אתה בטוח שברצונך למחוק רכיב זה? כל הבדיקות המשויכות יימחקו גם כן.',
      confirmDeleteTest: 'האם אתה בטוח שברצונך למחוק בדיקה זו?',
      emailRequired: 'נדרש דוא"ל',
      csvRequired: 'אנא בחר קובץ CSV',
      csvError: 'שגיאה בעיבוד קובץ CSV',
      usersUpdated: 'המשתמשים עודכנו בהצלחה',
    },

    headers: {
      actions: 'פעולות',
      id: 'מזהה',
      questionId: 'מזהה',
      testableId: 'מזהה',
      testId: 'מזהה',
      name: 'שם',
      testName: 'שם הבדיקה',
      questionName: 'שם השאלה',
      testableName: 'שם הרכיב',
      text: 'טקסט',
      difficulty: 'רמת קושי',
      type: 'סוג',
      className: 'שם המחלקה',
      initInput: 'קלט התחלתי',
      timeComplexity: 'סיבוכיות זמן',
      recursive: 'רקורסיבי',
      maxLoops: 'מקסימום לולאות',
      input: 'קלט',
      expected: 'פלט צפוי',
      postprocess: 'עיבוד לאחר',
      allowEachExpected: 'או',
      version: 'גרסה',
      userId: 'מזהה משתמש',
      email: 'דוא"ל',
      isPaying: 'משתמש בתשלום',
      expirationDate: 'תאריך תפוגה',
      institute: 'מוסד',
      department: 'מחלקה',
      tooltips: {
        actions: "פעולות אפשריות: עריכה, שכפול, מחיקה",
        id: "מזהה שנוצר אוטומטית",
        questionId: "מזהה שנוצר אוטומטית",
        testableId: "מזהה שנוצר אוטומטית",
        testId: "מזהה שנוצר אוטומטית",
        name: "שם הפריט",
        testName: "שם מקרה הבדיקה",
        questionName: "שם השאלה",
        testableName: "שם הרכיב הנבדק",
        text: "תיאור השאלה ודרישותיה",
        type: "סוג הרכיב (פונקציה/מתודה)",
        className: "שם המחלקה אם זו מתודה",
        initInput: "קלט לאתחול המחלקה",
        timeComplexity: "סיבוכיות זמן צפויה",
        recursive: "האם נדרשת רקורסיה",
        maxLoops: "מספר לולאות מקסימלי מותר",
        input: "ערך קלט לבדיקה",
        expected: "ערך פלט צפוי",
        allowEachExpected: 'אפשר התאמה עצמאית של כל ערך צפוי',
        postprocess: 'קוד פייתון לעיבוד הפלט לפני השוואה',
        version: "פורמט גרסה: מספר, אותיות, או מספר ואחריו אותיות",
        userId: 'מזהה ייחודי למשתמש',
        email: 'כתובת הדוא"ל של המשתמש',
        isPaying: 'האם המשתמש שילם עבור תכונות פרימיום',
        expirationDate: 'תאריך תפוגה של חשבון המשתמש',
        institute: 'מוסד לימודים של המשתמש',
        department: 'מחלקה או התמחות של המשתמש'
      }
    }
  },
  practice: {
    title: 'בודק קוד פייתון',
    darkmode: 'מצב כהה',
    lightmode: 'מצב בהיר',
    font: 'גודל גופן',
    run: 'הרץ',
    submit: 'הגש',
    output: 'פלט:',
    questionNotFound: 'השאלה {function_name} לא נמצאה.',
    didYouMean: 'האם התכוונת ל {match}?',
  },
  results: {
    functionName: 'שם הפונקציה',
    timeComplexity: 'סיבוכיות זמן',
    test: 'בדיקה',
    error: 'שגיאה',
    pass: 'עבר',
    fail: 'נכשל',
    noTest: 'אין בדיקה',
    noLimit: 'ללא הגבלה',
    input: 'קלט:',
    expected: 'פלט צפוי:',
    actual: 'פלט בפועל:',
    failedRecursion: 'הקוד צריך להיות רקורסיבי',
    failedLoops: 'השאלה מאפשרת {n_loops_allowed} לולאות, אך נמצאו {actual_loops}',
    failedStructure: 'מבנה לא תקין',
  },
  performance: {
    title: 'טבלת הגשות מעודכנת',
  },
  sign: {
    in: 'התחבר',
    up: 'הרשם',
    email: 'דוא"ל',
    name: 'שם',
    back: 'חזור',
    password: 'סיסמה',
    forgotPassword: 'שכחת סיסמה?',
    resetPassword: 'אפס סיסמה',
    codeWill: 'קוד איפוס יישלח לדוא"ל שלך:',
    sendCode: 'שלח קוד איפוס',
    defaultCode: 'הכנס קוד אימות',
    defaultPassword: 'הכנס סיסמה חדשה',
    confirmAccount: 'אמת את החשבון שלך',
    confirmationCode: 'קוד אימות',
    confirmAccountNow: 'אמת חשבון',
    resendCode: 'שלח קוד מחדש',
    changeSuccess: 'הסיסמה אופסה בהצלחה!',
  }
};

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';
import I18n from '../i18n';
import '../css/AdminPage.css';


const AdminPage = ({ userInfo }) => {
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);

  const [title, setTitle] = useState(I18n.t('admin.title'));
  const [loading, setLoading] = useState(I18n.t('basic.loading'));
  const [users, setUsers] = useState(I18n.t('admin.users'));
  const [questions, setQuestions] = useState(I18n.t('admin.questions'));


  useEffect(() => {
    setTitle(I18n.t('admin.title'));
    setLoading(I18n.t('basic.loading'));
    setUsers(I18n.t('admin.users'));
    setQuestions(I18n.t('admin.questions'));
  }, [language]); // Rerun whenever the language changes
  
  useEffect(() => {
    if (userInfo !== null) {
      const isAdmin = userInfo && userInfo.type === 'admin';
      if (!isAdmin) {
        navigate('/'); 
      }
    }
  }, [userInfo, navigate]);


  if (userInfo === null) {
    // Optionally render a loading indicator while userInfo is being fetched
    return <div>{loading}</div>;
  }

  return (
    <div className="admin-page">
      <h2>{title}</h2>

      <ul>
        <li><a href="/admin/users">{users}</a></li>
        <li><a href="/admin/questions">{questions}</a></li>
      </ul>

    </div>
  );
};

export default AdminPage;
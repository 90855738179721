export default {
  basic: {
    loading: 'جاري التحميل...',
    selectLanguage: 'اختر اللغة',
  },
  navbar: {
    home: 'الرئيسية',
    members: 'منطقة الأعضاء',
    account: 'الحساب',
    practice: 'نظام التدريب',
    performance: 'الأداء',
    signin: 'تسجيل الدخول',
    signup: 'إنشاء حساب',
    signout: 'تسجيل الخروج',
    about: 'عن الموقع',
    contact: 'اتصل بنا',
    admin: 'الإدارة',
  },
  home: {
    introTitle: 'مرحباً بكم في testapp!',
    introSubTitle: 'نظام تدريب فريد يتيح لك الاستعداد للامتحان مع',
    introSubTitle2: 'نجاح مضمون!',
    intro1: 'مرحباً',
    intro2: 'اسمي إليور شاكيد',
    intro3: 'أدعوكم لممارسة مهارات البرمجة الخاصة بكم بطريقة',
    intro4: 'إبداعية ومستقلة',
    intro5: 'يوفر نظامنا تغذية راجعة على اختبارات متنوعة للأسئلة الشائعة في الامتحان، ويتيح لك تقييم جودة الكود الخاص بك مع تلبية متطلبات السؤال (العودية، وقت التنفيذ، إلخ). رحلتنا طويلة، لكننا سنقوم بها معاً بهدف واضح - مساعدتك على كتابة كود مثالي',
    intro6: 'والنجاح في الامتحان',
    videoError: 'خطأ في تحميل الفيديو',
  },
  account: {
    title: 'صفحة الحساب',
    name: 'الاسم:',
    email: 'البريد الإلكتروني:',
    notPayingUser: 'أنت لست مستخدمًا مدفوعًا. يرجى الاتصال بالمسؤول لترقية حسابك.',
  },
  contact: {
    questions: 'لأية استفسارات:',
    email: 'البريد الإلكتروني:',
  },
  about: {
    founder1: 'إليور: ولد طيب',
    founder2: 'عميت: ولد شقي',
  },
  admin: {
    title: 'صفحة الإدارة - صفحة سرية!',
    upload: 'رفع ملف',
    default: 'لم يتم اختيار ملف',
    verify: 'تحقق ورفع CSV',
    alertNoFile: 'لم يتم اختيار ملف',
    alertSuccess: 'تمت معالجة الملف بنجاح',
    alertPleaseUpload: 'يرجى رفع ملف بامتداد .csv',
    questions: "الأسئلة",
    tests: "الاختبارات",
    addQuestion: "إضافة سؤال جديد",
    addTest: "إضافة اختبار جديد",
    testables: 'العناصر القابلة للاختبار',
    addTestable: 'إضافة عنصر قابل للاختبار',
    users: 'المستخدمون',
    searchUsers: 'البحث عن المستخدمين حسب الاسم أو البريد الإلكتروني أو المؤسسة أو القسم...',

    buttons: {
      edit: "تعديل",
      delete: "حذف",
      save: "حفظ",
      cancel: "إلغاء",
      duplicate: "نسخ",
      addQuestion: 'إضافة سؤال',
      addTestable: 'إضافة عنصر قابل للاختبار',
      addTest: 'إضافة اختبار',
      saving: 'جاري الحفظ...',
      deleting: 'جاري الحذف...',
      loading: 'جاري التحميل...',
      duplicating: 'جاري النسخ...',
      uploadConfirmedUsers: 'رفع المستخدمين المؤكدين',
    },

    messages: {
      saveSuccess: "تم حفظ التغييرات بنجاح",
      saveError: "خطأ في حفظ التغييرات",
      deleteSuccess: "تم الحذف بنجاح",
      deleteError: "خطأ في حذف العنصر",
      invalidPythonDict: "يجب أن يكون الإدخال بتنسيق قاموس بايثون صحيح (مثال: {'key': 'value'} أو {})",
      duplicateQuestionVersion: "يوجد بالفعل سؤال بهذا الاسم والإصدار",
      duplicateTestName: "يوجد بالفعل اختبار بهذا الاسم لهذا السؤال",
      invalidVersion: "يجب أن يكون الإصدار إما رقماً أو حروفاً أو رقماً متبوعاً بحروف",
      invalidExpectedOutput: "يجب أن يكون الناتج المتوقع رقماً أو True أو False أو None أو مجموعة أو نصاً بين علامتي اقتباس",
      deleteConfirm: 'هل أنت متأكد من حذف هذا العنصر؟',
      duplicateTestables: 'هل تريد نسخ العناصر والاختبارات المرتبطة أيضاً؟',
      duplicateSuccess: 'تم نسخ العنصر بنجاح',
      duplicateError: 'خطأ في نسخ العنصر',
      fetchError: 'خطأ في تحميل البيانات',
      nameRequired: 'الاسم مطلوب',
      duplicateQuestionName: 'يوجد بالفعل سؤال بهذا الاسم',
      duplicateTestableName: 'يوجد بالفعل عنصر قابل للاختبار بهذا الاسم لهذا السؤال',
      duplicateTestName: 'يوجد بالفعل اختبار بهذا الاسم لهذا العنصر',
      nameTooLong: 'يجب أن يكون الاسم أقل من 100 حرف',
      nameInvalidChars: 'يمكن أن يحتوي الاسم فقط على حروف وأرقام ومسافات وشرطات سفلية',
      duplicateNameSuffix: 'يوجد بالفعل نسخة من هذا العنصر بهذا الاسم. جرب لاحقة مختلفة.',
      confirmDeleteTestable: 'هل أنت متأكد من حذف هذا العنصر القابل للاختبار؟ سيتم حذف جميع الاختبارات المرتبطة به أيضاً.',
      confirmDeleteTest: 'هل أنت متأكد من حذف هذا الاختبار؟',
      emailRequired: 'البريد الإلكتروني مطلوب',
      csvRequired: 'الرجاء اختيار ملف CSV',
      csvError: 'خطأ في معالجة ملف CSV',
      usersUpdated: 'تم تحديث المستخدمين بنجاح',
    },

    headers: {
      actions: 'الإجراءات',
      id: 'المعرف',
      questionId: 'المعرف',
      testableId: 'المعرف',
      testId: 'المعرف',
      name: 'الاسم',
      testName: 'اسم الاختبار',
      questionName: 'اسم السؤال',
      testableName: 'اسم العنصر القابل للاختبار',
      text: 'النص',
      difficulty: 'مستوى الصعوبة',
      type: 'النوع',
      className: 'اسم الفئة',
      initInput: 'الإدخال الأولي',
      timeComplexity: 'التعقيد الزمني',
      recursive: 'عودي',
      maxLoops: 'الحد الأقصى للحلقات',
      input: 'الإدخال',
      expected: 'الناتج المتوقع',
      postprocess: 'المعالجة اللاحقة',
      allowEachExpected: 'أو',
      version: 'الإصدار',
      userId: 'معرف المستخدم',
      email: 'البريد الإلكتروني',
      isPaying: 'مستخدم مدفوع',
      expirationDate: 'تاريخ انتهاء الصلاحية',
      institute: 'المؤسسة',
      department: 'القسم',
      tooltips: {
        actions: "الإجراءات المتاحة: تعديل، نسخ، حذف",
        id: "معرف تم إنشاؤه تلقائياً",
        questionId: "معرف تم إنشاؤه تلقائياً",
        testableId: "معرف تم إنشاؤه تلقائياً",
        testId: "معرف تم إنشاؤه تلقائياً",
        name: "اسم العنصر",
        testName: "اسم حالة الاختبار",
        questionName: "اسم السؤال",
        testableName: "اسم العنصر القابل للاختبار",
        text: "وصف السؤال ومتطلباته",
        type: "نوع العنصر (دالة/طريقة)",
        className: "اسم الفئة إذا كانت طريقة",
        initInput: "إدخال لتهيئة الفئة",
        timeComplexity: "التعقيد الزمني المتوقع",
        recursive: "هل العودية مطلوبة",
        maxLoops: "الحد الأقصى المسموح به للحلقات",
        input: "قيمة إدخال الاختبار",
        expected: "قيمة الناتج المتوقع",
        allowEachExpected: 'السماح بمطابقة كل قيمة متوقعة بشكل مستقل',
        postprocess: 'كود بايثون لمعالجة الناتج قبل المقارنة',
        version: "تنسيق الإصدار: رقم، حروف، أو رقم متبوع بحروف",
        userId: 'معرف فريد للمستخدم',
        email: 'عنوان البريد الإلكتروني للمستخدم',
        isPaying: 'ما إذا كان المستخدم قد دفع مقابل الميزات المتقدمة',
        expirationDate: 'تاريخ انتهاء صلاحية حساب المستخدم',
        institute: 'المؤسسة التعليمية للمستخدم',
        department: 'قسم أو تخصص المستخدم'
      }
    }
  },
  practice: {
    title: 'مدقق كود بايثون',
    darkmode: 'الوضع الداكن',
    lightmode: 'الوضع الفاتح',
    font: 'حجم الخط',
    run: 'تشغيل',
    submit: 'إرسال',
    output: 'الناتج:',
    questionNotFound: 'لم يتم العثور على السؤال {function_name}.',
    didYouMean: 'هل تقصد {match}؟',
  },
  results: {
    functionName: 'اسم الدالة',
    timeComplexity: 'التعقيد الزمني',
    test: 'اختبار',
    error: 'خطأ',
    pass: 'نجاح',
    fail: 'فشل',
    noTest: 'لا يوجد اختبار',
    noLimit: 'بدون حد',
    input: 'الإدخال:',
    expected: 'الناتج المتوقع:',
    actual: 'الناتج الفعلي:',
    failedRecursion: 'يجب أن يكون الكود عودياً',
    failedLoops: 'يسمح السؤال بـ {n_loops_allowed} حلقات، لكن تم العثور على {actual_loops}',
    failedStructure: 'بنية غير صحيحة',
  },
  performance: {
    title: 'جدول التقديمات المحدث',
  },
  sign: {
    in: 'تسجيل الدخول',
    up: 'إنشاء حساب',
    email: 'البريد الإلكتروني',
    name: 'الاسم',
    back: 'رجوع',
    password: 'كلمة المرور',
    forgotPassword: 'نسيت كلمة المرور؟',
    resetPassword: 'إعادة تعيين كلمة المرور',
    codeWill: 'سيتم إرسال رمز إعادة التعيين إلى بريدك الإلكتروني:',
    sendCode: 'إرسال رمز إعادة التعيين',
    defaultCode: 'أدخل رمز التأكيد',
    defaultPassword: 'أدخل كلمة المرور الجديدة',
    confirmAccount: 'تأكيد حسابك',
    confirmationCode: 'رمز التأكيد',
    confirmAccountNow: 'تأكيد الحساب',
    resendCode: 'إعادة إرسال الرمز',
    changeSuccess: 'تم إعادة تعيين كلمة المرور بنجاح!',
  }
};
